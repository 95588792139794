import React from 'react'
// Images
import chevronRight from 'img/chevron-right.svg'
// CSS
import styled from 'styled-components'
// Components
import CustomLink from 'components/CustomLink'
// Third Party
import parse from 'html-react-parser'

const StyledContactBox = styled.div`  
  width: 340px;
  height: 450px;
`

const StyledCustomLink = styled(CustomLink)`
  :hover {
    font-weight: 900;
  }

  p {
    padding: 12px 22px;
    border-radius: 5px;
    max-width: 190px;
    transition: 0.25s;

    :hover {
      transform: scale(1.05);
    }
  }
`

const StyledImg = styled.img`
  height: 12px !important;
  width: 18px !important;
`

const BoxItem = styled.div`
  padding-left: 44px;
  ${props => props.top && `padding-top: 29px;`}
  ${props => props.bottom && `padding-bottom: 38px;`}
`

const ContactBox = ({ className, content }) => {
  return (
    <StyledContactBox className={`${className ? `${className}` : ``} d-flex pl-0 pl-lg-4 justify-content-end`}>
      <div className="color-background-secondary w-100">
        <BoxItem top>
          <h2 className="m-0 mb-1 font-weight-xl font-size-ml">{content.title}</h2>
          <p className="m-0 font-size-m color-text-main">{content.sub_title}</p>
        </BoxItem>

        <BoxItem className="pt-4 mt-3 pb-5">
          <StyledCustomLink to="/contact">
            <p
              className="font-size-ms d-flex justify-content-center align-items-center color-background-contrast color-text-light">
              {content.button_text}
            </p>
          </StyledCustomLink>
        </BoxItem>

        <BoxItem bottom>
          <h2 className="font-weight-xl mb-2 font-size-ml">{content.link_title}</h2>
          {content.link_group.map((link, index) => (
            <div key={index} className="py-1 my-1 pl-2">
              <StyledImg src={chevronRight} alt="" />
              <StyledCustomLink to={link.link.url} className="m-0 font-size-m color-text-main">
                {parse(link.link.title)}
              </StyledCustomLink>
            </div>
          ))}
        </BoxItem>
      </div>
    </StyledContactBox>
  )
}

export default ContactBox