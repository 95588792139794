import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
// Components
import Content from 'components/Content'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import ContactBox from 'components/ContactBox'
// CSS
import 'styles/ServicesDetail.scss'
// Third party
import parse from 'html-react-parser'
import styled from 'styled-components'

// Images

const StyledBackgroundImage = styled(BackgroundImage)`
	@media (max-width: 991px) {
		p, strong {
			font-size: 18px !important;
		}
	}
`

const sensoryMarketing = ({ data, pageContext }) => {
  const {
    wordpressPage: {
      title,
      acf: {
        sensory_title_section: titleSection,
        sensory_banner: sensoryBanner,
        sensory_campagnes: sensoryCampagnes,
        sensory_meer_informatie: moreInformation,
      },
    },
  } = data

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(to left, rgba(0, 53, 120, 0) 32%, #001843)`,
    ].reverse()

    return gradient
  }

  return (
    <Layout extraFooterPiece>
      <SEO title={title} />
      <div className="service">
        <section className="empty-space-50 color-background-main d-flex align-items-center">
          <div className="container">
            <p className="text-white font-family-main font-size-xm mb-0">{parse(title)}</p>
          </div>
        </section>

        <section className="title mb-5">
          <div className="container mt-2">
            <div className="mb-5">
              <Breadcrumb crumbSeparator={' > '} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={parse(title)} />
            </div>

            <div className="row">
              <div className="col-lg-8">
                <div className="d-flex align-items-center mb-4">
                  <img src={titleSection.image.localFile.publicURL} alt="" />
                  <h1 className="ml-4 mb-0 font-weight-xl font-size-h1">{titleSection.title}</h1>
                </div>
                <Content content={titleSection.description} />
              </div>
              <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end">
                <ContactBox className="pt-5 mt-4" content={moreInformation} />
              </div>
            </div>
          </div>
        </section>

        <StyledBackgroundImage
          fluid={addGradient(sensoryBanner.image.localFile.childImageSharp.fluid)}
          className="background-banner mb-5"
          tag="section"
        >
          <div className="container py-5">
            <Content
              className="nested py-lg-5 my-3 font-weight-xl color-text-light font-size-h1 line-height-s"
              content={sensoryBanner.text}
            />
          </div>
        </StyledBackgroundImage>

        <div className="container mb-5">
          {sensoryCampagnes.map((campagne, index) => (
            <div className={`row mb-5 ${index % 2 ? 'flex-row-reverse' : 'flex-row'}`}>
              <div className="col-lg-6">
                <GatsbyImage fluid={campagne.image.localFile.childImageSharp.fluid} />
              </div>
              <div className="col-lg-6 pt-5 pl-5 pr-5">
                <h2 className="font-weight-xl font-size-l mb-4">{parse(campagne.title)}</h2>
                <Content content={campagne.description} />
                {
                  // <Link className="button button-alt px-3" to={campagne.link.url}>
                  //   {parse(campagne.link.title)}
                  // </Link>
                }
              </div>
            </div>
          ))}
        </div>
        <div className="empty-space-100 mb-5" />
      </div>
    </Layout>

  )
}

export default sensoryMarketing

export const pageQuery = graphql`
	query SensoryMarketingQuery($wordpress_id: Int!) {
		wordpressPage(wordpress_id: { eq: $wordpress_id }) {
			id
			wordpress_id
			title
			slug
			acf {
				sensory_title_section {
					image {			
            localFile {
              publicURL
            }
					}
					title
					description
				}

				sensory_banner {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 1920) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					text
				}

				sensory_campagnes {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 620) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					title
					description
				}

				sensory_meer_informatie {
					title
					sub_title
					button_text
					link_title
					link_group {
						link {
							url
							title
						}
					}
				}

			}
		}
	}
`